import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitalState, StatusType } from '../../types/common.type';
import { getPaymentMethods, TransactionDetailResponse, transactionsDetails } from '../actions/payment.action';
import { PaymentCard, PaymentMethodsResponse } from '../../types';
import { ChangedDateInfo, PaymentModal, TransactionType } from '../../types/contract.type';

const changedDateInfo: ChangedDateInfo = {
  amount: 0,
  late_fee_ob: {
    late_fee: 0,
    missing_payments: 0,
    pf_id: 0,
    pf_status: 0
  },
  show_connected_option: false
}
interface PaymentsInitialState extends InitalState {
  default_payment_methods: PaymentCard | null;
  backup_payment_methods: PaymentCard | null;
  payment_methods: PaymentCard[];
  transactionDetails: TransactionType[];
  changedDateInfo: ChangedDateInfo | null,
  activePaymentModal: PaymentModal | null,
  selectedPaymentMethod: PaymentCard | undefined,
  status: StatusType;
}

const initialState: PaymentsInitialState = {
  payment_methods: [],
  backup_payment_methods: null,
  default_payment_methods: null,
  transactionDetails: [],
  changedDateInfo: changedDateInfo,
  activePaymentModal: null,
  selectedPaymentMethod: undefined,
  status: StatusType.Idle
};

const paymentsReducer = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setChangedDateInfo: (state, action: PayloadAction<ChangedDateInfo>) => {
      state.changedDateInfo = action.payload;
    },
    setActivePaymentModal: (state, action: PayloadAction<PaymentModal | null>) => {
      state.activePaymentModal = action.payload;
    }
    ,
    setSelectedPaymentMethod: (state, action: PayloadAction<PaymentCard | undefined>) => {
      state.selectedPaymentMethod = action.payload || undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethods.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(getPaymentMethods.fulfilled, (state, action: PayloadAction<PaymentMethodsResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.original_text
            state.flag = action.payload.flag
          }
          else {
            const payment_cards = action.payload.payment_cards && action.payload.payment_cards.length ? action.payload.payment_cards : [];
            const source_id: number = action.payload.source_id || 0;
            const secondary_source_id = action.payload.secondary_source_id || 0;
            const default_payment_methods = payment_cards.filter((item: { source_id: number; }) => item.source_id === source_id);

            const backup_payment_methods = payment_cards.filter((item: { source_id: number; }) => item.source_id === secondary_source_id);

            const payment_methods = payment_cards.filter(
              (item: { source_id: number; }) => item.source_id !== source_id && item.source_id !== secondary_source_id
            );

            state.default_payment_methods = default_payment_methods && default_payment_methods.length ? default_payment_methods[0] : null;
            state.backup_payment_methods = backup_payment_methods && backup_payment_methods.length ? backup_payment_methods[0] : null;
            state.payment_methods = payment_methods || [];
            state.status = StatusType.Succeeded;
          }
        }

      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.status = StatusType.Failed;
        const error: string = typeof action.payload === 'object' && action.payload !== null ? '' : (action.payload as string);
        state.error = error || 'Failed to fetch payment detail';
        state.flag = 401;
      })
      .addCase(transactionsDetails.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(transactionsDetails.fulfilled, (state, action: PayloadAction<TransactionDetailResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.message
            state.flag = action.payload.flag
          }
          else {
            state.transactionDetails = action.payload.transaction;
            state.status = StatusType.Succeeded;
          }
        }

      })
      .addCase(transactionsDetails.rejected, (state, action) => {
        state.status = StatusType.Failed;
        state.error = action.payload || 'Failed to fetch transaction list';
        state.flag = 401;
      })
  }
});

export const { setChangedDateInfo, setActivePaymentModal, setSelectedPaymentMethod } = paymentsReducer.actions;
export default paymentsReducer.reducer;
