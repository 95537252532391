import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusType } from '../../types/common.type';
import { AuthResponse, CustomerProfile, LoginAfterReset } from '../../types';
import { authenticate, AuthRejectResponse, loginAfterResetPass, logout, LogoutResponse } from '../actions/auth.action';
import { routes_constants } from '../../service/constants';
interface AuthInitialState {
  access_token: string | null;
  reset_password_token: string | null;
  patient_email: string | null;
  isAuthenticated: boolean;
  customer: CustomerProfile | null;
  status: StatusType;
  error?: string | null;
  flag?: number | null;
  toaster: boolean;
}

const initialState: AuthInitialState = {
  access_token: null,
  reset_password_token: null,
  isAuthenticated: false,
  customer: null,
  status: StatusType.Idle,
  error: '',
  flag: null,
  toaster: false
} as AuthInitialState;

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, (state) => {
        // TODO: Need to fix below
        state.status = StatusType.Idle;
      })
      .addCase(authenticate.fulfilled, (state, action: PayloadAction<AuthResponse>) => {
        state.status = StatusType.Succeeded;

        if (action.payload.reset_password_token) {
          state.reset_password_token = action.payload.reset_password_token;
          state.patient_email = action.payload.patient_email;
          action.payload.navigate(routes_constants.change_password);
        }
        else {
          state.access_token = action.payload.access_token;
          state.customer = {
            patient_first_name: action.payload.patient_first_name,
            patient_last_name: action.payload.patient_last_name,
            cust_code: action.payload.cust_code,
            patient_id: action.payload.patient_id
          }
          state.isAuthenticated = true;
        }
      })
      .addCase(authenticate.rejected, (state, action: PayloadAction<AuthRejectResponse | undefined>) => {
        state.status = StatusType.Failed;
        state.error = action?.payload?.message || 'Failed to fetch auth details';
        state.flag = action?.payload?.flag || 401;

        if (action.payload?.flag === 9 && action.payload.navigate) {
          action.payload.navigate(routes_constants.register);
        }
      })
      .addCase(loginAfterResetPass.fulfilled, (state, action: PayloadAction<LoginAfterReset>) => {
        state.access_token = action.payload.access_token;
        state.customer = {
          patient_first_name: action.payload.patient_first_name,
          patient_last_name: action.payload.patient_last_name,
          cust_code: action.payload.cust_code,
          patient_id: action.payload.patient_id
        }
        state.isAuthenticated = true;
        state.reset_password_token = null;
      })
      .addCase(logout.pending, (state) => {
        state.status = StatusType.Idle;
      })      
      .addCase(logout.fulfilled, (state, action: PayloadAction<LogoutResponse>) => {
        state.status = StatusType.Succeeded;
        if(action.payload.success && action.payload?.navigate){
          state = initialState
          action.payload.navigate(routes_constants.login);
        }
      })
      .addCase(logout.rejected, (state, action: PayloadAction<AuthRejectResponse | undefined>) => {
        state.status = StatusType.Failed;
        state.error = action?.payload?.message || 'Failed to logout';
        state.flag = action?.payload?.flag || 401;
      });      
  },
});

export default authReducer.reducer;

