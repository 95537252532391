import "../my-profile.scss";
import { Row, Col, Button, Form } from "react-bootstrap";

export const ChangePassword = () => {
  return (
    <>
      <>
        <div className="px-5 mxw-600">
          <h4 className="fs-20 fw-600 m-0">Change Password</h4>
          <p className="fs-16 fw-400 m-0 mb-4">
          For better protection, avoid using a previously used password.
          </p>

          <Form>
            <Row>
              <Col xs={12} className="mb-3">
                <label className="fs-14 fw-500 text-muted">Current Password</label>
                <input type="text" className="input_field w-100" placeholder="Enter your current password"
                />
              </Col>

              <Col xs={12} className="mb-3">
                <label className="fs-14 fw-500 text-muted">New Password</label>
                <input type="text" className="input_field w-100" placeholder="Enter new password"
                />
              </Col>

              <Col xs={12} className="mb-3">
                <label className="fs-14 fw-500 text-muted">Confirm Password</label>
                <input type="text" className="input_field w-100" placeholder="Confirm Password"
                />
              </Col>
            </Row>

          <div className="d-flex align-items-center">
          <Button variant="secondary" type="submit" className="mnw-125 py-2 me-3">
              Save
            </Button>
            <Button variant="outline" className="mnw-125 py-2">
             Cancel
            </Button>
          </div>
          </Form>
        </div>
      </>
    </>
  );
};
