import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Row, Col, Button, Form } from "react-bootstrap";
import API from '../../../service/api.service';
import './my-profile.scss';
// Define the Yup validation schema
const schema = yup.object().shape({
  dob: yup.date().required("Date of Birth is required"),
  address: yup.string().required("Address is required"),
  zip: yup
    .string()
    .required("Zip code is required")
    .matches(/^\d{5}$/, "Zip code must be exactly 5 digits"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  profileImage: yup
    .mixed()
    .required("Profile image is required")
});

export const MyProfile = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const [previewImage, setPreviewImage] = useState<any>('');

  const onSubmit = async (data: any) => {
    console.log("Form data submitted: ", data);
    const response = await API({
      api: `customer/edit_profile`,
      body: data,
      type: 'POST'
    });

      
  };

  // Function to handle the image preview
  const handleImageChange = (e: any) => {
    const file = e?.target?.files && e?.target.files.length ? e?.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
   <div className="px-5">
   <h4 className="fs-20 fw-600 m-0">My Profile</h4>
      <p className="fs-16 fw-400 m-0 mb-4">Update your photo and personal details here</p>

      <div className="d-flex align-items-center mb-3">
        <img
          src={previewImage || "../../assets/images/place-holder.png"}
          alt="Avatar"
          className="rounded-circle hw-80 me-3"
        />
          <h4 className="fs-20 fw-600 m-0">Test</h4>
        <Form.Group className="ml-3 d-none">
          <Form.Control
            type="file"
            {...register("profileImage")}
            onChange={(e) => {
              handleImageChange(e);
              register("profileImage").onChange(e); // Ensures file is registered
            }}
            isInvalid={!!errors.profileImage}
          />
          <Form.Control.Feedback type="invalid">
            {errors.profileImage?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </div>

      <Row className="pb-4 my-4 border-bottom">
          <Col  md={4}>
           <p  className="fs-14 fw-400 text-muted m-0">
           Email:
           </p>
           <h4 className="fs-16 fw-600 m-0 ">
           flores_bert@email.com
           </h4>
          </Col>
          <Col  md={4}>
            <p className="fs-14 fw-400 text-muted m-0"> 
            Phone Number:
            </p>
            <h4 className="fs-16 fw-600 m-0 ">
            +1-354-654-6545
            </h4>
          </Col>
          <Col md={4}>
           <p  className="fs-14 fw-400 text-muted m-0">
           SSN Number:
           </p>
           <h4 className="fs-16 fw-600 m-0 ">
           *****8144
           </h4>
          </Col>
        </Row>


      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
        <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">First Name</label>
          <input type="text" className="input_field w-100" placeholder="Enter First Name" />
          </Col>
          
          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">Last Name</label>
          <input type="text" className="input_field w-100" placeholder="Enter Last Name" />
          </Col>

          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">Date of Birth</label>
          <input type="text" className="input_field w-100" placeholder="Enter DOB" />
          </Col>
          
          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">Address</label>
          <input type="text" className="input_field w-100" placeholder="Enter Your Address" />
          </Col>

          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">Zip Code</label>
          <input type="text" className="input_field w-100" placeholder="Enter Zip Code" />
          </Col>

          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">City</label>
          <input type="text" className="input_field w-100" placeholder="Enter City" />
          </Col>

          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">State</label>
          <input type="text" className="input_field w-100" placeholder="Enter State" />
          </Col>
        </Row>


        <Button variant="secondary" type="submit" className="mnw-125">
          Save
        </Button>
      </Form>
   </div>
    </>
  );
};
